import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "leisure-activities-at-the-castle"
    }}>{`Leisure activities at the Castle`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.104294478527606%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB80lEQVQY0wHoARf+ACY/Eyc7ES5BFSc3EiQyETREFjtQGyI1FCU4FCc7FB8xHDBHGzBIEiE4DjlRDE1oK0RZMjVFISxNWjFgbgAoPxMuRRU3VBg9WBk4TBcuQBArPhUZKREqQBY0SxYtRRQySg06ThcjNw8fLQdYbSRUZCk2RQ0zVE1DgJMAHCcPHSYQIi8VHS0NKjoTLjocLzgsJjImGCQJEB0EGigNHzAKHigMMDoQITASPlAaWGkyQE0eO1AkSVtaAA8RDg0XBAwdAjA5Km16a5aopKi/xafDynyRl0lcTREbDBEWCxYcDis2Dy88HDxZQVdrPktbNExhRGySnQAWJg0iNh9JWk6ImIpQWko2LCFpW1Odnpa1xMKuw8V3goMfIx0YJREwQC1ESixbZFJRZTNLXitOZVUsOT8Ac4hQk6eSq7utsb+xmbGjbIpvY2lVdHtllKKSmaSdq7Wuhop4ZmU3X1w/YGI/UlQnPU8OPVUaJC4VBAAAAHOFPbenjcm0ndG8psnGtKrU0XvZ5VTR4ljR3TjO6DTK4lDO41XM3lqfoYOXhIaGYFRaI4iFYCsoICEiGABddSKemm3UvKzTvKjewarjwqvnzbfc0sHP2MvE2My82Mu21sqo083Az8S2sJ6Wh2lWXixRVUYwLyMyNiY73qgr4ePXgAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4712e030725ca58ca34943dab647655d/f994e/LesLoisirsPiscine2.webp 163w", "/static/4712e030725ca58ca34943dab647655d/e93cc/LesLoisirsPiscine2.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4712e030725ca58ca34943dab647655d/cf3f8/LesLoisirsPiscine2.png 163w", "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4712e030725ca58ca34943dab647655d/eed55/LesLoisirsPiscine2.png",
              "alt": "Leisure at the Castle of Morey",
              "title": "Leisure at the Castle of Morey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Leisure at the Castle of Morey`}</strong></p>
    <h2 {...{
      "id": "need-to-relax-and-unwind-in-the-heart-of-lorraine"
    }}>{`Need to relax and unwind in the heart of Lorraine?`}</h2>
    <h3 {...{
      "id": "a-moment-of-well-being-between-nancy-and-metz"
    }}>{`A moment of well-being between Nancy and Metz`}</h3>
    <p>{`Come and find comfort at Château de Morey.
Take advantage of our `}<em parentName="p">{`private SPA`}</em>{` with 25 adjustable massage jets in water at 38 degrees with essential oils of `}<strong parentName="p">{`Lorraines`}</strong>{`.
Relax in a zen atmosphere in this small warm and unusual cocoon.`}</p>
    <h3 {...{
      "id": "for-a-relaxing-massage-in-your-room"
    }}>{`For a relaxing massage in your room:`}</h3>
    <p>{`Contact Celine at `}<a parentName="p" {...{
        "href": "tel:+33661347300",
        "target": "_blank",
        "rel": "noreferrer"
      }}>{`06 61 34 73 00`}</a></p>
    <h2 {...{
      "id": "the-activities-of-the-castle-of-morey"
    }}>{`The activities of the Castle of Morey`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.104294478527606%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB80lEQVQY0wHoARf+ABgmECA0FS5GFic8EyxGEChEDSA4DRYnCDNPEkZgGkdeMU+Vt0if10Wk2UCr41twjW88OnA6IYErFHoxJwAYIw8UHwQLFwESHggfNA0XJwcUIAgeLxQZKws4UgtKWg1XiHpXruFsjrGdXXaga41iia5DcHRFXz4zV1QAjaiwcIiNUWRYHCYUBwwAFRwKFiAJHi8QL00/O11AOk4bXYJrd7rgjXeFnVlgqmVukYaVWn+CQk4fKDMSAKi+w5/AyZzCyoOorTtJSgQJBB4uHSk0IHqZpXOaqj5VQIKmsJO6y4/B1n6yyIK7zIq8z2yHjTpEIiw5GwB9Y06hnY6jnZWtraWwr6dYWEIwNRxeUz6BcVRRWUMvTBU+XTsoOh9BWEBMXz1CVS46Wz81SD4hJwsiLQsAVJCIXaajZaaqWqmxYa+2c7a5aKSbeqichqqTlKqMsK6EkIxqeXded3NWhodegoJdVl5AGBwRHSIKKzURAH7W5GLS4mDZ40Tc80Ha71XY6k7Y7GHW6IvY48be2fPVwqCJhXRjaGtaYWhkbWFZWkA2NhsbFSYrEDA3GgDlwavkx7Hhy7fjzLfky7Tmya/qyavtyrDwy7DtxqzkxKzTtZ/GqpaZg3VQSkUrMCYzNyojIhEyORc2Ox5vP8V+cif5BgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26edbe93598e303a68bea7e6a402e2c0/f994e/LesLoisirsPiscine.webp 163w", "/static/26edbe93598e303a68bea7e6a402e2c0/e93cc/LesLoisirsPiscine.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26edbe93598e303a68bea7e6a402e2c0/cf3f8/LesLoisirsPiscine.png 163w", "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/26edbe93598e303a68bea7e6a402e2c0/eed55/LesLoisirsPiscine.png",
              "alt": "Leisure in Lorraine",
              "title": "Leisure in Lorraine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Leisure in Lorraine`}</strong></p>
    <h3 {...{
      "id": "a-relaxation-complex"
    }}>{`A relaxation complex`}</h3>
    <p>{`You will be able to take advantage of a maximum of well being space.
Our inground swimming pool built in 2001, allows you to sunbathe, while being surrounded by a park with trees and the sound of its birds. A moment of relaxation guaranteed.
Near `}<em parentName="p">{`Nancy and Metz`}</em>{`, refresh yourself during the very hot seasons of `}<em parentName="p">{`Lorraine`}</em>{`.`}</p>
    <h3 {...{
      "id": "enjoy-multiple-activities-in-our-home-"
    }}>{`Enjoy multiple activities in our home :`}</h3>
    <ul>
      <li parentName="ul">{`💦 Spa`}</li>
      <li parentName="ul">{`💆 Massage`}</li>
      <li parentName="ul">{`🏊 Pool`}</li>
      <li parentName="ul">{`🐴 Horseback riding`}</li>
      <li parentName="ul">{`🏐 Boulodrome`}</li>
      <li parentName="ul">{`🎱 Billiard`}</li>
      <li parentName="ul">{`🎲 Games room`}</li>
      <li parentName="ul">{`🌳 An exceptional wooded park`}</li>
      <li parentName="ul">{`🚴 Bikes at your disposal`}</li>
      <li parentName="ul">{`🌐 Free Wifi`}</li>
      <li parentName="ul">{`📚 Library`}</li>
      <li parentName="ul">{`🐾 Animals`}</li>
    </ul>
    <p>{`Our bucolic park consists of a vast garden supported by a wall more than 5 meters high.
With chestnut trees, Lebanese cedar, lime trees, purple beeches, oaks, maple and a duck pond.
A perfect place to walk and relax.`}</p>
    <h2 {...{
      "id": "visit-the-lorraine"
    }}>{`Visit the `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d85ff176205ab28d0b0779e0558eabf6/af98b/placestan.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.87116564417178%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHm2zhFsTLP/8QAGRABAQEAAwAAAAAAAAAAAAAAAAERAhIT/9oACAEBAAEFAvOpwdY1a1//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIT/9oACAEDAQE/AXCM5P/EABcRAAMBAAAAAAAAAAAAAAAAAAABAhP/2gAIAQIBAT8BVM0o/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRABAQADAQAAAAAAAAAAAAAAAQARMVGh/9oACAEBAAE/IeGGQ3CG/IRKwzl//9oADAMBAAIAAwAAABB07//EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAwEBPxClu//EABYRAQEBAAAAAAAAAAAAAAAAAAABcf/aAAgBAgEBPxCNg//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQXFR/9oACAEBAAE/ECsUD2RByByszF/Eypqs6S5hLVfMgFUvSf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d85ff176205ab28d0b0779e0558eabf6/f994e/placestan.webp 163w", "/static/d85ff176205ab28d0b0779e0558eabf6/bcd70/placestan.webp 325w", "/static/d85ff176205ab28d0b0779e0558eabf6/5d8d7/placestan.webp 650w", "/static/d85ff176205ab28d0b0779e0558eabf6/c4443/placestan.webp 975w", "/static/d85ff176205ab28d0b0779e0558eabf6/5cc32/placestan.webp 1300w", "/static/d85ff176205ab28d0b0779e0558eabf6/448ad/placestan.webp 1613w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/d85ff176205ab28d0b0779e0558eabf6/367e5/placestan.jpg 163w", "/static/d85ff176205ab28d0b0779e0558eabf6/ab07c/placestan.jpg 325w", "/static/d85ff176205ab28d0b0779e0558eabf6/ebabe/placestan.jpg 650w", "/static/d85ff176205ab28d0b0779e0558eabf6/cdb69/placestan.jpg 975w", "/static/d85ff176205ab28d0b0779e0558eabf6/2616f/placestan.jpg 1300w", "/static/d85ff176205ab28d0b0779e0558eabf6/af98b/placestan.jpg 1613w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d85ff176205ab28d0b0779e0558eabf6/ebabe/placestan.jpg",
              "alt": "place stanislas",
              "title": "place stanislas",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Place stanislas de Nancy`}</strong></p>
    <h3 {...{
      "id": "located-between-nancy-and-metz-the-discovery-of-these-charming-cities-could-well-amaze-you"
    }}>{`Located between `}<strong parentName="h3">{`Nancy and Metz`}</strong>{`, the discovery of these charming cities could well amaze you.`}</h3>
    <p>{`Nancy, 20 minutes from the Château de Morey, is known for its magnificent Place Stanislas and for its old town which will immerse you in the Middle Ages of Lorraine. Many museums can be found in this city full of history.`}</p>
    <p>{`Metz, 30 minutes from the Château de Morey, owes its fame to its sumptuous cathedral and the city’s Germanic imperial architecture. Let yourself be guided in the charms of this city which transcribes multiple eras.`}</p>
    <h3 {...{
      "id": "the-lorraine-hosts-many-sites-and-monuments-to-see-from-all-eras-it-is-a-privilege-to-have-a-region-so-rich-in-history"
    }}>{`The Lorraine hosts many sites and monuments to see, from all eras, it is a privilege to have a region so rich in history.`}</h3>
    <p>{`Just 15 minutes from the Château de Morey, Pont à Mousson is a small town full of charm, it has many local restaurants, but also some exceptional monuments. The best known is the Premonstratensian Abbey, a monument of the 18th century, it is a magnificent example of the monastic architecture of Lorraine.`}</p>
    <p>{`A few kilometres further away you will find :`}</p>
    <ul>
      <li parentName="ul">{`Verdun and its historical sites of the Second World War`}</li>
      <li parentName="ul">{`The Madina Lake and the Montsec Hill Monument`}</li>
    </ul>
    <p>{`For Château enthusiasts, we have colleagues in the region :`}</p>
    <ul>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://www.chateau-malbrouck.com/",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Malbrouck`}</a></li>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://fr.wikipedia.org/wiki/Ch%C3%A2teau_de_Lun%C3%A9ville",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Luneville`}</a></li>
      <li parentName="ul">{`Le Château de `}<a parentName="li" {...{
          "href": "https://www.tourisme-lorraine.fr/a-voir-a-faire/visites/sites-et-monuments/995000870-chateau-de-haroue-haroue",
          "target": "_blank",
          "rel": "noreferrer"
        }}>{`Haroue`}</a></li>
    </ul>
    <p>{`You can also visit, Alsace, Luxembourg, Strasbourg, Lac de pierre percé etc…`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      